'use client';
import Link from 'next/link';
import styles from './error.module.scss';
import errorImg from '@/services/utils/icons/error.png';
import Img from '@/components/molecules/img';

const Error = ({ error, reset }: { error?: Error; reset?: () => void }) => (
    <main className="container">
        <div className={`${styles.error_main} row`}>
            <div className="col-md-5">
                <br />
                <br />
                <br />
                <br />
                <br />
                <p>There was a problem</p>
                <h1>{(error && error.message) || 'Something went wrong!'}</h1>
                <p>Please try again later or contact support if the problem persists</p>
                <div className={styles.button_list}>
                    <button
                        onClick={() => {
                            reset && reset();
                        }}
                    >
                        Try again
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Link href="/">Go Back Home</Link>
                </div>
                <Img src={errorImg} />
            </div>
        </div>
    </main>
);
export default Error;
